/* TabLabelWithBadge.css */

.tabLabelContainer {
  position: relative; /* Establishes a positioning context for absolute children */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.badgeContainer {
  position: absolute;
  bottom: 5;
  top: 0; /* Adjust based on your design */
  left: 0; /* Adjust based on your design */
  z-index: 2; /* Ensure badges are above the tab but below any dropdowns */
}
.titleContainer {
  font-size: 1em; /* Match the font size to the other tabs */
}

.descriptionContainer {
  font-size: 0.75em; /* Match the font size to the other tabs, if they have descriptions */
  color: grey;
}

.badge {
  margin: 0 4px; /* Spacing between badges, if needed */
  padding: 2px 6px; /* Badge padding */
  border-radius: 12px; /* Rounded corners for badges */
  color: white; /* Text color for badges */
  font-size: 0.7em; /* Font size for badges */
  display: inline-block; /* Align badges in a row */
}

.blackBadge {
  background-color: black;
}

.redBadge {
  background-color: red;
}

.title {
  font-size: 1em; /* Match the font size to other tabs */
  color: inherit; /* Inherit color from parent */
  font-weight: 500; /* Adjust to match other tabs */
}
.description {
  font-size: 0.8em; /* Match the font size to other tabs descriptions */
  color: inherit; /* Inherit color from parent */
  margin-top: 4px; /* Space between title and description */
}

.badgeContainer {
  /* This will position the badges in the top-left corner of the tab */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

/* Style for the badges */
.badge {
  margin: 0 4px; /* Keep or adjust spacing between badges */
  padding: 6px 12px;
  border-radius: 15px; /* Adjust for rounded corners, can be increased for larger badges */
  color: white;
  font-size: 0.85em; /* Increase font size for the number inside the badge */
  display: inline-block;
  min-width: 20px; /* Optionally set a minimum width */
  text-align: center; /* Center the text within the badge */
}

.blackBadge {
  background-color: #000;
}

.redBadge {
  background-color: #f00;
}

/* Existing style for the description, now explicitly setting the color */
.tabDescription {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  color: inherit; /* Ensure this is not a string */
  font-size: 0.8rem;
}
