/* Responsive container for the slider */
.responsiveSliderContainer {
  padding: 0 15px;
  width: 100%;
}
.responsiveSlider .MuiSlider-valueLabel {
  top: -30px; /* Adjust position of value label */
  font-size: 1rem; /* Larger font size for better visibility */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .responsiveSliderContainer .MuiSlider-thumb {
    height: 24px; /* Larger touch target for mobile */
    width: 24px; /* Larger touch target for mobile */
  }

  .responsiveSlider .MuiSlider-valueLabel {
    top: -40px; /* Adjust position of value label for visibility */
    font-size: 0.875rem; /* Adjust font size for better visibility */
    /* Ensure the label is above other content */
    z-index: 1;
  }

  .responsiveSlider .MuiSlider-thumb {
    height: 28px; /* Larger thumb for better touch target on mobile */
    width: 28px; /* Larger thumb for better touch target on mobile */
  }
  .responsiveSlider .MuiSlider-root {
    margin-top: 80px; /* Give more space to ensure labels are not cut off */
  }
}
