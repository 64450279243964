.relativeContainer {
  position: relative;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.customCard {
  flex: 0 0 auto;
  /* this prevents the cards from shrinking or growing */
  margin: 1.5in;
  /* this creates a half-inch margin around each card */
  width: 300px;
  border: 2px solid #000000;
  justify-content: space-between;
}

.customCard > * {
  flex: 1 1 100%;
}

.ribbon {
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: absolute;
  left: 10px;
}

.ribbonSpan {
  position: absolute;
  display: block;
  width: 200px;
  padding: 10px 0;
  background-color: #7fffd4;
  color: #7fffd4;
  text-align: center;
  font-size: 0.8rem;
}

.ribbonTopLeftSpan {
  right: -20px;
  top: 20px;
  transform: rotate(-45deg);
}

.priorityCard {
  box-shadow: 0 0 0 10px #ff0000; /* Replace with the color you want */
  position: relative;
}

.priorityRibbon,
.oldSlotRibbon {
  position: absolute;
  top: 13px; /* Adjust these values */
  left: -5px; /* Adjust these values */
  width: 40px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  background-color: #006400; /* Dark green color */
  color: #fff;
  padding: 5px 10px; /* Adjust these values */
  transform: rotate(-45deg) skew(-20deg);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3); /* Adds a nice shadow */
  border-radius: 50% 0 50% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.priorityRibbon {
  background-color: #006400; /* Dark green color */
  font-size: 10px;
}

.oldSlotRibbon {
  background-color: #121111;
  text-align: center;
  line-height: 10px; /* Reduced from whatever you have, possibly around 30px */
  letter-spacing: 1px;
  font-size: 9px;
  font-weight: bold;
  padding: 5px; /* Adjust padding to fine-tune the fit */
  overflow: hidden; /* Hides parts of the text that overflow the area */
}

.agedScroll {
  background-color: #f8f0e3; /* light beige paper color */
  background-image: radial-gradient(circle, #f9f9f9 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #f9f9f9 20%, transparent 30%),
    radial-gradient(circle, #f9f9f9 20%, transparent 20%),
    radial-gradient(circle, #f9f9f9 20%, transparent 20%),
    linear-gradient(#f7e8d5 2px, transparent 2px),
    /* updated for a beige line */
      linear-gradient(90deg, #f7e8d5 2px, transparent 2px),
    /* updated for a beige line */ linear-gradient(#f7e8d5 1px, transparent 4px),
    /* lighter beige lines */
      linear-gradient(90deg, #f7e8d5 1px, transparent 4px); /* lighter beige lines */
  background-size: 10px 10px, /* size for fine grain circles */ 20px 20px,
    /* size for medium grain circles */ 30px 30px,
    /* size for large grain circles */ 40px 40px,
    /* size for largest grain circles */ 5px 5px,
    /* fine line horizontal stripes */ 5px 5px,
    /* fine line vertical stripes */ 6px 6px,
    /* medium line horizontal stripes */ 6px 6px; /* medium line vertical stripes */
  border: 1px solid #d0b088; /* softer border color for beige */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* softer shadow for a subtle effect */
  color: #5a4a32; /* dark beige text color */
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  padding: 10px;
  border-radius: 4px;
}

.slotSummaryContainer {
  display: grid;
  grid-template-rows: auto auto; /* Defines two rows: one for the slot time and one for icons */
  width: 100%;
  gap: 10px; /* Space between rows */
  border: 1px solid #ccc; /* Optional: border around the entire container for clarity */
  background-color: #fff; /* Background color for the entire container */
}

.slotSummaryContainer {
  display: flex;
  flex-direction: column; /* Ensures vertical stacking */
  width: 100%;
}

.slotTimeRow {
  display: flex;
  flex-direction: column; /* Change from 'row' to 'column' */
  align-items: center; /* Center items horizontally */
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 4px;
  border: 1px solid #ccc;
  padding: 1px;
}

.slotIconsRow {
  display: flex;
  flex-direction: row; /* Ensure icons are in a row */
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
  width: 100%;
}

.slotDetails {
  display: block;
  border: 1px solid #ccc; /* subtle border for clarity */
  padding: 1px; /* more space inside */
  margin: 4px; /* space between slots */
  min-height: 120px;
}

.slotDetails,
.slotSummaryContainer {
  position: relative;
}

.selectedSlot {
  background-color: #f0f0f0; /* grey background for selected slots */
}

.buttonGroup {
  display: flex;
  gap: 8px; /* Space between buttons */
}

.slotDetailButton {
  display: flex;
  height: 30px;
  width: 60%;
  margin-top: 30px;
  left: 25px;
}

/* Styling for the select and deselect buttons */
.selectButton,
.deselectButton {
  min-width: 50px;
  height: 24px;
  font-size: 0.75rem;
}
