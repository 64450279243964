.fullWidth {
  width: 100%;
}

.mobileTitle {
  margin-top: 16px;
  margin-bottom: 40px;
  text-align: center;
}

/* Add this new class for the sticky container on mobile */
.mobileStickyHeader {
  width: 100%;
  position: sticky;
  top: 70px; /* Adjust based on your existing header height */
  background: white;
  z-index: 1000; /* Ensure it stays above other content */
  padding: 10px; /* Optional: Adds padding for better spacing */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth */
  display: flex; /* Enables flexbox layout */
  flex-direction: column; /* Stacks dropdowns vertically */
  gap: 10px; /* Space between dropdowns */
}

/* Optional: If you prefer a separate wrapper for dropdowns */
.dropdownWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between dropdowns */
}

/* Ensure the sticky container spans the full width on mobile */
.fullWidthStickyContainer {
  width: 100%;
}

.mobileSelectContainer {
  width: 100%;
  margin-top: 15px;
}

.fullWidthFormControl {
  width: 100%;
}

.selectFontSize {
  font-size: 14px;
}

.marginTopFlex {
  margin-top: 20px;
  display: flex;
}

.loadingIndicator {
  position: absolute;
  right: 30px;
  margin-top: -60px;
  width: 140px;
}

.flexDisplay {
  display: flex;
}

.paperContent {
  height: 500px;
  overflow-x: auto;
  padding-bottom: 50px;
  width: 100%;
  overflow-y: hidden;
}

.legendContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.legendItem {
  display: flex;
}

.legendText {
  text-transform: capitalize;
}

.colorBox {
  height: 10px;
  margin-top: 7px;
  width: 20px;
  margin-left: 10px;
  margin-right: 20px;
}

.customTooltip {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: black;
  cursor: pointer;
  border: 1px solid #e3e1e1;
  border-radius: 3px;
  color: white;
  font-size: 13px;
}

.formControlWidth {
  width: 150px;
}

.outlineNone {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.typographyMarginTop {
  margin-top: 10px;
}

@media (min-width: 700px) {
  .legendContainer {
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .formControlWidth {
    width: 100%;
  }
}
