.gridContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
  padding: 10px;
}

.mainContainer {
  margin-top: 100px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    padding: 10px;
  }
  .mainContainer {
    margin-top: 80px;
    margin-bottom: 20px;
    margin-left: -12px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;
    width: 100%;
  }
}
