.mobile-questionnaire-list {
  display: none; /* Hide by default, show only in mobile view */
}

.selectFiltersWrapper {
  display: flex;
  column-gap: 10px;
}

.datePickerInput {
  text-align: center;
  border: 1px solid black;
  display: block; /* Make it a block element if it's not already */
  margin: 0 auto; /* Center it horizontally */
}

.centeredContent {
  text-align: center;
}

.hover-text {
  cursor: pointer;
  text-decoration: underline;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

li {
  margin-bottom: 10px;
}

.hover-text {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 5px;

  .copy-button {
    display: block;
    margin: 16px auto;
    padding: 10px 20px;
  }

  .copy-icon {
    margin-right: 8px;
  }
}

/* Style for the date picker input */
.date-picker-input {
  text-align: center;
  border: 1px solid black;
  display: block; /* Make it a block element if it's not already */
  margin: 0 auto; /* Center it horizontally */
}

/* Styles for the checkbox, directly specifying the size */
.checkbox-input {
  -webkit-appearance: none; /* Remove default style */
  appearance: none;
  width: 20px; /* Directly set width */
  height: 20px; /* Directly set height */
  border: 1px solid #ccc; /* Custom border */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition */
}

.checkbox-input:checked {
  background-color: #007bff; /* Background color when checked */
  border-color: #007bff; /* Border color to match */
}

.checkbox-input:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Custom focus indicator */
}

/* Style for the "Sent..." text */

.sent-text {
  font-size: 1.75em; /* Example: 25% larger than the current font size */
  color: #666; /* Lighter text color */
  margin-top: 4px; /* Space between checkbox and text */
}

.snoozed {
  background-color: rgba(
    255,
    235,
    59,
    0.5
  ); /* Example: A light yellow overlay */
}

.responseCell {
  cursor: pointer; /* Makes it clear the cell is clickable */
}

.diagnosisTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.diagnosisTable th,
.diagnosisTable td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

.diagnosisTable th {
  background-color: #f2f2f2;
}

.diagnosisTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.centeredRadio {
  text-align: center;
  transform: scale(1.5); /* Adjust the scaling factor as needed */
}
.centeredRadio input[type='radio'] {
  transform: scale(1.5); /* Adjust the scaling factor as needed */
  margin-right: 10px; /* Space between the radio button and label */
  cursor: pointer; /* Indicates the radio button is clickable */
}

@media (max-width: 768px) {
  .desktopView {
    display: none;
  }
  .mobileQuestionnaireList {
    display: block;
  }
  .mobileQuestionnaireCard {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    margin-left: 17px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
  }
  .questionnaireClientName,
  .questionnaireClientProvider {
    font-weight: bold;
  }
  .questionnaireClientDetails {
    margin-top: 10px;
    text-align: left;
  }
}

@media (min-width: 769px) {
  .mobileQuestionnaireList {
    display: none;
  }
}
