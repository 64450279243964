/* customHandsontableStyles.css */
.cellCentered {
  text-align: center;
  vertical-align: middle;
  white-space: normal; /* For text wrapping */
}

.htCore .htColumnHeadersTh {
  font-weight: bold;
  background-color: #fff; /* Match your table's background color */
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px; /* Adjust spacing between filters */
  margin-top: 15px;
}

.filterGroup {
  display: flex;
  flex-direction: column;
}

.filterGroupLabel {
  margin-bottom: 5px;
}

.filterGroupSelect {
  width: 150px; /* Adjust width of dropdowns */
}
