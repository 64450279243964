/* UserManagement.module.css */

.button {
  margin-right: 8px;
}

.deleteUserPopup {
  margin-left: 20px;
}

.formControl {
  margin: 8px;
  min-width: 250px;
}

.formControlWide {
  min-width: 300px; /* Adjust this value as needed */
  width: auto; /* This makes the width adjust based on its content up to a point */
}

.imgCenter {
  text-align: -webkit-center;
}

.messageSupervisee {
  display: block;
  margin-left: 10px;
  margin-top: 10px;
  line-height: 1;
  font-weight: 50;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.54);
}

.tableContainer {
  margin-top: 20px;
}
