/* Form container styles */
.formContainer {
  background-color: #ecedf3;
  padding: 2.8rem 20%; /* Increase the right and left padding */
}

/* Form content styles */
.formContent {
  background-color: #fff;
  width: 782px;
  padding: 1.5rem;
  margin: auto;
  border: 1px solid lightblue; /* Added: Light blue border */
}

/* Centered form header styles */
.formHeaderCentered {
  text-align: center;
  color: rgb(46, 109, 164);
  font-weight: 600;
  line-height: 2.45;
  font-size: 1.8em;
  font-family: Inter, sans-serif;
  border-bottom: 1px solid lightblue;
  margin-bottom: 1rem;
}

/* Space before the first question */
.spaceBeforeFirstQuestion {
  margin-bottom: 2rem;
}

/* Form field container */
.formField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

/* Form label styles */
.formLabel {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: rgb(46, 109, 164);
  margin-bottom: 0.5rem;
}

/* Wide form input styles */
.formInputWide {
  width: calc(100% - 2rem);
  height: 45px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.formInputWide:focus {
  border-color: #007bff; /* Optional: change this color to whatever you prefer */
}

/* Faded color for input placeholders of .form-input-wide */
.formInputWide::placeholder {
  color: #b3b3b3; /* Faded color for placeholder text */
  opacity: 1; /* Full opacity for placeholder text */
}

/* Small form input styles for Patient Legal Name and Date of Birth */
.formInputSmall {
  width: calc((100% - 4rem) / 4);
  height: 45px;
  margin-right: 1rem;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.formInputSmall:focus {
  border-color: #007bff; /* Optional: change this color to whatever you prefer */
}

.apologyMessage {
  color: red; /* Red color */
  font-weight: bold; /* Bold font */
  font-size: 1.2em; /* Larger font size, adjust as needed */
}

.apologyMessage {
  color: red;
  /* font-weight: bold;  */
  font-size: 1.1em;
}

/* Adjust the font-size for smaller screens */
@media screen and (max-width: 768px) {
  .apologyMessage {
    font-size: 1em; /* Smaller font size on smaller screens */
  }
}

/* Form textarea styles */
.formTextarea {
  width: 90%;
  height: 180px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.formTextarea:focus {
  border-color: #007bff; /* Optional: change this color to whatever you prefer */
}

/* Dropdown input styles */
.formDropdown {
  width: 330px;
  height: 45px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

.formDropdown:focus {
  border-color: #007bff; /* Optional: change this color to whatever you prefer */
}

.addMargin {
  margin-right: 20px; /* Add space to the right of the dropdown */
}

.formInputMedium {
  width: 220px;
  height: 45px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.formInputMedium:focus {
  border-color: #007bff; /* Optional: change this color to whatever you prefer */
}

.formRow {
  position: relative;
}

.trashContainer {
  position: relative;
}

.trashButton {
  position: absolute;
  bottom: 58px; /* Adjust this value to move the button vertically */
  right: -670px; /* Adjust this value to move the button horizontally */
  background: none;
  border: 1px solid #ccc; /* Add a small border */
  border-radius: 4px; /* Round the corners a bit */
  cursor: pointer;
  font-size: 24px;
  padding: 2px 4px; /* Add a little padding so the border doesn't cut into the icon */
  color: red; /* light red color */
}

.trashButton:hover {
  border-color: rgb(164, 46, 46); /* Change border color on hover */
  color: rgb(164, 46, 46); /* Change font color on hover */
}

.trashIcon {
  color: red; /* Or any other color you like */
}

/* Existing Submit button styles */
.submitButton {
  color: #ffffff;
  background-color: #337ab7;
  width: 200px;
  border-color: #2e6da4;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: Inter, sans-serif;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 3em;
  margin-top: 20px; /* Added: White space above */
  margin-bottom: 20px; /* Added: White space below */
  margin: 20px auto;
}

.submitButton2 {
  color: #ffffff;
  background-color: #4caf50; /* More inviting green color */
  border-color: #4caf50; /* Consistent border color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover */
}

.submitButton2:hover {
  background-color: #45a049; /* Slightly darker on hover for effect */
  border-color: #45a049; /* Consistent hover border */
}

/* Separator line */
.separatorLine {
  height: 1px; /* Thickness of the line */
  background-color: lightgrey; /* Color of the line */
  margin-top: 40px; /* Space above the line */
  margin-bottom: 20px;
  width: 100%; /* Make the line extend the full width of the white area */
}

.centerText {
  text-align: center; /* Center text */
  padding: 20px; /* Add padding */
  margin: 10px; /* Add margin */
}

@media screen and (max-width: 768px) {
  .formContainer {
    padding: 2.8rem 5%;
  }

  .formContent {
    width: auto;
    padding: 1.5rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .formInputWide,
  .formInputMedium,
  .formDropdown,
  .formTextarea {
    width: 100%;
    font-size: 18px;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .formField {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .formLabel,
  .formInputWide,
  .formInputSmall,
  .formInputMedium,
  .formDropdown {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .trashButton {
    right: 10px; /* Adjust for smaller screens */
    bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .formHeaderCentered {
    font-size: 1.2em; /* Adjust font size as necessary */
    padding: 0 10px; /* Add padding to prevent text from breaking */
  }
}

/* Ensure the radio labels and inputs are inline */
.radioLabel {
  display: inline-flex;
  align-items: center;
  margin-right: 20px; /* Adjust as necessary for spacing */
}

/* Specific styling for the radio input */
.radioLabel input[type='radio'] {
  margin-right: 5px; /* Space between radio button and label text */
}

/* Remove the flex-direction column from the media query for small screens */
@media screen and (max-width: 768px) {
  .radioGroup {
    /* Removed flex-direction: column; */
    justify-content: flex-start; /* Align to the start of the row */
  }
}

.containerTextArea {
  width: 100%;
  padding: 0.5rem;
}

.customHeight {
  max-height: 219px;
}

.referredColumn {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .referredColumn {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.customWidthEmail {
  max-width: 224px;
  margin-top: 10px;
}

.customContainer {
  margin-left: 2rem;
}

.preferredDropDown {
  margin-top: 23px;
}
.captachaWarning {
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: rgb(46, 109, 164);
  margin-top: 2rem;
}

/* Faded color for the select display */
.fadedSelectDisplay {
  color: #b3b3b3; /* Adjust the color as needed */
}

/* Faded color for the default select option */
.formDropdown option[disabled] {
  color: #b3b3b3; /* Faded color for disabled option */
}

/* Faded color for the date input when it's empty */
.fadedDateDisplay {
  color: #b3b3b3; /* Faded color */
}

/* Add a new class in your CSS file */
.radioGroupNegativeMargin {
  margin-top: -20px; /* Adjust the value as needed */
}

/* Add new CSS for the labels in your stylesheet */
.radioLabelSpacing {
  margin-right: 20px; /* Adjust the value to increase spacing */
}

.elqRequired {
  color: red;
}

#testingIssue {
  font-size: 0.8em; /* Adjust the size as needed */
}

.captachaContainer {
  transform: scale(0.85);
  transform-origin: 0 0;
}

@media screen and (max-width: 430px) {
  .captachaContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    transform: scale(0.85);
    transform-origin: center;
  }
}
