/* Set a variable for sidebar width for easy adjustments */
:root {
  --sidebar-width: 200px; /* Adjust based on actual sidebar width */
}

/* Filter - NotUsed */
.filter {
  margin-bottom: 20px;
}

.sideBarDiv {
  margin-left: -250px;
}

/* Table Styles */

/* NotUsed */

/* table {
  width: 85%;
  border-collapse: collapse;
  margin-bottom: 20px;
} */

.tableSidebarCollapsed {
  width: 100%; /* Full width when the sidebar is hidden */
}

/* Adjust table width when sidebar is expanded */
/* body.sidebar-expanded table { */
body.sidebarExpanded table {
  /* width: calc(100% - var(--sidebar-width)); */
  width: 85%;
}

/* Adjust margin for content-container when sidebar is expanded */
/* body.sidebar-expanded .content-container { */
body.sidebarExpanded .contentContainer {
  margin-right: var(--sidebar-width);
  transition: margin-right 0.3s;
}

/* Full width for content-container when sidebar is hidden */
/* body:not(.sidebar-expanded) .content-container { */
/* body:not(.sidebarExpanded) .contentContainer {  
margin-right: 0;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
} */

/* Remove border from last row */
/* tbody tr:last-child td {
  border-bottom: none;
} */

/* Add hover effect */
/* tbody tr:hover {
  background-color: #f5f5f5;
} */

/* Field selection dropdown */
/* .field-selection { */
.fieldSelection {
  margin-bottom: 20px;
}

/* .field-selection select { */
.fieldSelection select {
  width: 80%;
  height: 100px;
  display: block;
  /* margin: 0 auto; */
}

/* .filters-container { */
.filtersContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping in case the width is too small */
}

.filter,
.fieldSelection {
  flex-basis: 30%; /* You can adjust this if needed */
  margin: 0 10px;
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  right: 0;
  top: 8%; /* start 10% from the top */
  height: 92%; /* take up the remaining 90% of the height */
  width: 15%; /* 1/8 of the screen */
  overflow-y: auto;
  background-color: #f5f5f5; /* add a background color if desired */
  padding: 20px; /* add some padding for inner content */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* optional shadow for a subtle separation effect */
  /* Add these lines */
  transform: translateX(100%); /* Initially move sidebar offscreen */
  transition: transform 0.3s ease-in-out; /* Smooth transition for sidebar */
}

/* .sidebar-visible { */
.sidebarVisible {
  transform: translateX(0); /* Move sidebar into view */
  z-index: 2;
}

/* .sidebar-button { */
.sidebarButton {
  width: 100%; /* make the button take the full width of the sidebar */
  margin-bottom: 10px; /* add some space between buttons */
  padding: 10px 15px; /* some padding for the button */
  border: none; /* remove default border */
  border-radius: 5px; /* slight rounding of corners */
  cursor: pointer; /* change cursor to pointer on hover */
  font-size: 16px; /* adjust font size as needed */
  display: flex; /* to align icon and text side-by-side */
  align-items: center; /* vertically center icon and text */
  justify-content: center; /* horizontally center icon and text */
}

/* .sidebar-button.search { */
.sidebarButtonSearch {
  background-color: blue;
  color: white; /* text color */
}

/* .sidebar-button.clear { */
.sidebarButtonClear {
  background-color: darkgrey;
  color: white; /* text color */
}

/* Add some space between the icon and the text */
/* .sidebar-button .icon { */
.sidebarButton .icon {
  margin-right: 10px;
}
/* .sidebar-input { */
.sidebarInput {
  position: relative;
  margin-bottom: 25px;
}

/* .sidebar-input label { */
.sidebarInput label {
  margin: 2; /* Ensure no additional margin for the checkbox label */
  display: block;
}

.sidebarInput select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.sidebarInput input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
/* Styling for the Date picker */
/* .MuiFormControl-root { */
.MuiFormControlRoot {
  width: 100%;
}

.checkboxContainer {
  display: flex;
  align-items: center; /* This will vertically center the checkbox and the label */
  justify-content: flex-start; /* Aligns children elements to the start of the container */
}

/* .checkbox-label { */
.checkboxLabel {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #333;
  padding-bottom: 5px;
  white-space: nowrap;
}

.firstNameInput {
  margin-bottom: 10px; /* Adjust this value to control the space between First Name and Last Name inputs */
}

.emailInput {
  margin-bottom: 10px; /* Adjust this value to control the space between inputs */
  margin-top: -15px; /* Adjust this value to move Email up */
}

.phoneInput {
  margin-bottom: 10px; /* Adjust this value to control the space between inputs */
}

/* .dob-picker .MuiFormControl-root { */
.dobPicker .MuiFormControlRoot {
  margin-bottom: 115px !important;
  margin-top: -110px !important;
}

/* Add a bottom border to the label within the sidebar-input div */
.sideBarInputLabel {
  /* .sidebar-input label { */
  border-bottom: 1px solid #333;
  padding-bottom: 5px;
}

.sidebarInputTherapists {
  /* .sidebar-input.therapists { */
  margin-top: 15px;
}

/* .sidebar-input.status { */
.sidebarInputStatus {
  margin-top: -25px;
}

/* .sidebar-input.primary-insurance { */
.sidebarInputPrimaryInsurance {
  margin-top: -20px;
}

/* .sidebar-input.secondary-insurance { */
.sidebarInputSecondaryInsurance {
  margin-top: -20px;
}

.therapistsPlaceholder {
  height: 10px; /* adjust this value based on your needs */
}
/* Center the radio buttons horizontally */
.centeredRadio {
  text-align: center;
  transform: scale(1.5);
}

.centeredRadio input[type='radio'] {
  transform: scale(1.5); /* Adjust the scaling factor as needed */
  margin-right: 10px; /* Space between the radio button and label */
  cursor: pointer; /* Indicates the radio button is clickable */
}

/* Highlight the selected row in light yellow */
/* tbody tr.selected-row > td { */
tbody tr.selectedRow > td {
  background-color: lightyellow;
}
/* CSS for the bottom menu bar */

.statusButton {
  cursor: pointer;
  text-align: center;
}

.dots {
  font-size: 24px; /* Large dots */
  line-height: 1; /* Adjust line height to tighten the spacing */
}

/* .status-text { */
.statusText {
  font-size: 16px;
}

.cancelButton {
  cursor: pointer;
  font-size: 24px; /* Size of the 'X' */
  line-height: 1;
  /* Additional styles for positioning and appearance as needed */
}

/* .list-item-button { */
.listItemButton {
  cursor: pointer; /* Makes the mouse turn into a click hand */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* .list-item-button:hover { */
.listItemButton:hover {
  background-color: #e0e0e0; /* Color when the item is hovered */
}

/* .list-item-button.selected { */
.listItemButtonSelected {
  background-color: blue; /* Color when the item is selected */
  color: white;
}

.modal {
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for overlay */
  z-index: 1000; /* Make sure it's above other elements */
}

/* .modal-content { */
.modalContent {
  max-width: 500px !important;
  width: 70%;
  padding: 20px; /* Add padding inside the modal */
  /* Your other styles */
  background-color: white; /* Optional: Set background color */
  border-radius: 5px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for depth */
  display: flex; /* New: Organizes children in a flex layout */
  flex-direction: column; /* New: Stacks children vertically */
  justify-content: space-between; /* New: Aligns children with space between them */
  max-height: 80vh; /* New: Sets a max height for the modal */
  overflow: hidden; /* New: Prevents modal from overflowing */
  /* Remove margin: 0 auto; as it's no longer needed */
}

.scrollableContent {
  overflow-y: auto; /* New: Allows scrolling for overflow content */
  margin-bottom: 20px; /* New: Space above the button row, adjust as necessary */
}

.confirmationModal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.confirmationModalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; /* Make sure it's above other items */
}

/* .confirmation-section p,
.confirmation-section ul,
.confirmation-section ul li { */
.confirmationSection p,
.confirmationSection ul,
.confirmationSection ul li {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.checkboxContainer {
  margin-top: 0; /* Zero out space above the checkbox container */
}

.visibilityIcon {
  /* position: fixed; */
  position: absolute;
  /* top: 65px; */
  right: 10px; /* Adjust as necessary for correct horizontal alignment */
  z-index: 1000; /* Ensures it's above other content */
  right: 20px;
  bottom: 20px;
  background-color: transparent; /* To make it look more like an icon */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.menuIcon {
  /* position: fixed; */
  position: absolute;
  top: 95px; /* Adjust as necessary for correct vertical alignment */
  right: 10px; /* Adjust as necessary for correct horizontal alignment */
  z-index: 1000; /* Ensures it's above other content */
  /* right: 20px; */
  /* bottom: 20px; */
  background-color: transparent; /* To make it look more like an icon */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

/* If using Material-UI icons or similar */
/* .visibility-icon .MuiSvgIcon-root,
.menu-icon .MuiSvgIcon-root { */
.visibilityIcon .MuiSvgIconRoot,
.menuIcon .MuiSvgIconRoot {
  fill: #007bff; /* Example color */
  width: 24px; /* Icon size */
  height: 50px; /* Icon size */
  transition: background-color 0.3s, transform 0.3s;
}

/* .visibility-icon .MuiSvgIcon-root.sidebar-visible,
.menu-icon .MuiSvgIcon-root.sidebar-visible { */
.visibilityIcon .MuiSvgIconRoot.sidebarVisible,
.menuIcon .MuiSvgIconRoot.sidebarVisible {
  transform: rotate(90deg); /* Rotate icon when sidebar is visible */
}

/* Additional styles for hover effect (optional) */
/* .visibility-icon:hover,
.menu-icon:hover { */
.visibilityIcon:hover,
.menuIcon:hover {
  background-color: #f0f0f0; /* Light grey for hover state */
  transform: scale(1.1); /* Slightly increase size on hover */
}

.tabDescription {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  color: 'inherit';
  font-size: 0.8rem; /* Adjust size as necessary */
}

.customTabRoot {
  text-transform: none;
  min-width: 192px;
  min-height: 48px;
  padding: 0;
}

.customTabSelected {
  background-color: #003366 !important; /* Darker blue */
  color: white !important;
}

.dropdownContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%; /* Allow it to flex within the grid item */
}

/* Hide mobile view on larger screens */
.mobileClientList {
  display: none;
}

/* Styles for mobile client cards */
/* .mobile-client-card .client-details.hide { */
.mobileClientCard .clientDetails.hide {
  display: none;
}

.switchLabel {
  margin-left: 10px;
  font-size: 1.1rem; /* Default font size */
  font-weight: bold;
}

@media (max-width: 768px) {
  /* Hide table on smaller screens */

  .container {
    padding-left: 0;
    padding-right: 0;
    /* margin-left: 20px; */
    /* margin-right: 60px; */
  }

  table {
    display: none;
  }

  .menuIcon {
    top: 65px !important;
  }

  /* Show mobile view on smaller screens */
  .mobileClientList {
    display: block;
    /* margin-left: 15px; */
    width: 100%;
    margin-bottom: 40px;
    margin-top: -20px;
    box-sizing: border-box;
    margin: 0 auto; /* optional if you want it centered */
    margin-left: 0; /* Avoid large left margins if possible */
    margin-right: 0;
  }

  /* Additional styling for the mobile view */
  .mobileClientCard {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
  }

  .clientName {
    width: 100%; /* Adjust the width to fit mobile screens */
    box-sizing: border-box; /* Ensures padding is included in the width */
    padding: 0 15px; /* Adds some padding inside the box */
    margin: 0 auto; /* Centers the box if needed */
    /* Additional styling as needed */
  }
  .clientDetails {
    font-size: smaller;
    color: #000; /* Adjusted for visibility */
  }

  .mobileDropdown {
    display: block;
    margin: '0 auto';
    margin-top: 40px; /* Adjust as needed to push it down */
    /* margin-left: 20px; */
    padding-left: 30px; /* Adjust as needed */
    border: 1px solid blue; /* Thin blue border */
    padding: 5px; /* Optional: Adds some padding inside the border */
    box-sizing: border-box; /* Ensures padding and border are included in the element's dimensions */
    text-align: 'center';
    transform: 'translateX(3%)';
  }

  .tableSidebarCollapsed {
    display: none !important;
  }

  .switchContainer {
    /* Adjust padding or margins as needed for smaller screens */
    padding: 10px 0; /* Add some padding on the top and bottom */
    margin-top: -40px; /* Add some margin on the top */
  }

  .switchContainer span {
    font-size: 0.9rem; /* Increase font size for better readability on mobile */
  }

  .switchLabel {
    font-size: 0.9rem; /* Larger font size for better readability on mobile */
    /* Add any additional mobile-specific styles here */
  }
  .MuiSwitchRoot {
    /* Assuming you're using Material-UI */
    transform: scale(0.8); /* Makes the switch smaller */
  }

  .dropdownContainer {
    display: none;
  }

  .sidebar {
    width: 0; /* Sidebar should not take space when not visible */
    /* Update other styling properties for mobile */
  }
  /* .sidebar-visible { */
  .sidebarVisible {
    width: 65%; /* Adjust this value if you need more or less space */
    /* other styling properties necessary for visibility on mobile */
  }
  /* .conditional-switch-container { */
  .conditionalSwitchContainer {
    flex-grow: 4;
    flex-basis: 100%; /* Take full width on smaller screens */
    max-width: 100%; /* Allow the container to expand fully */
    justify-content: left; /* Adjust alignment if necessary */
    margin-top: 5px; /* Increase margin-top for better spacing */
    padding: 0 1px; /* Add some padding for smaller screens */
  }
}

@media screen and (min-width: 1441px) {
  .dropdownContainer {
    margin-right: 175px;
  }
}

.popupBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Dimmed backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.popupBox {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: adds shadow for better visibility */
  width: auto; /* Auto width based on content, or set a fixed width */
}

.popupActions {
  text-align: right;
  margin-top: 10px; /* Spacing above the buttons */
}

.introText {
  font-size: 20px; /* Adjust as needed */
  margin-bottom: 16px; /* Spacing between text and form */
}

.infoIcon {
  position: relative;
  font-size: 16px; /* Adjust the size of the icon as needed */
  cursor: help; /* Changes the cursor to indicate it's hoverable */
}

.infoIcon:hover::after {
  content: attr(tooltip); /* This pulls the text from the tooltip attribute */
  position: absolute;
  bottom: 100%; /* Positions the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; /* Tooltip background */
  color: #fff; /* Tooltip text color */
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  width: 200px; /* Adjust the width of the tooltip */
  z-index: 1001;
}

.radioLabel {
  display: flex;
  align-items: center; /* Vertically centers the radio button with the label */
  margin-bottom: 10px; /* Adds some space between each radio button option */
}

/* .radio-label.selected { */
.radioLabelSelected {
  color: black;
}

/* .radio-label.not-selected { */
.radioLabelNotSelected {
  color: lightgray; /* Text color for non-selected options */
}

.radioLabel .radioButton {
  margin-right: 2px; /* Adds some space between the radio button and the label text */
}

.radioInput {
  margin-right: 8px; /* Space between radio button and label text */
}

.confirmationSection {
  max-height: 50vh; /* Adjust this value based on your modal size */
  overflow-y: auto; /* Adds a scrollbar when content overflows */
  padding: 10px 0; /* Optional: Adds some vertical padding inside the confirmation section */
}

.containerTotalClients {
  display: flex;
  justify-content: flex-end;
  margin-right: 140px;
}

.visibilityOffIcon {
  /* position: fixed; */
  position: absolute;
  /* top: 65px; */
  right: 10px; /* Adjust as necessary for correct horizontal alignment */
  z-index: 2000; /* Ensures it's above other content */
  right: 20px;
  bottom: 20px;
  background-color: transparent; /* To make it look more like an icon */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

@media (max-width: 600px) {
  .visibilityOffIcon {
    bottom: 75px;
  }
}
