/* Tab Container */

.tabContainer {
  margin-top: 20px;
  width: 100%;
}

.widerProviderDropdown {
  width: 100%;
  min-width: 100px; /* Or whatever minimum width makes sense for your design */
}

/* Media Queries for Responsive Design */

/* For screens smaller than 600px wide */
@media only screen and (max-width: 600px) {
  /* Add responsive styles for smaller screens here */
  /* Example: */
  .tabContainer {
    margin-top: 10px;
  }
  .widerProviderDropdown {
    width: 100%;
  }
  .selectWithDarkBorder {
    width: 100%;
    border: 3px solid #0033cc; /* Dark blue border */
    border-radius: 4px; /* Rounded corners */
  }
}

/* For screens between 601px and 992px wide */
@media only screen and (min-width: 601px) and (max-width: 992px) {
  /* Add responsive styles for medium screens here */
  /* Example: */
  .tabContainer {
    margin-top: 15px;
  }
}

/* For screens larger than 992px (Desktop) */
@media only screen and (min-width: 993px) {
  .tabContainer {
    margin-top: 60px; /* Increased margin for desktop */
  }
}
