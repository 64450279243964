.mainContainer {
  margin-top: 100px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  width: 100%;
}
/* New Style added by me */
.tabsContentContainer {
  margin-top: 20px;
}
/* .Primary_details_bx { */
.PrimaryDetailsBx {
  width: 100%;
}
/* .Primary_details_bx tr th:first-child { */
.primaryDetailsBx tr th:first-child {
  min-width: 145px;
}
/* .Primary_details_bx h5 { */
.primaryDetailsBx h5 {
  color: #ffffff;
  background: #3f51b5;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 0;
  font-size: 16px;
}
/* .Primary_details_bx tr:first-child td,
.Primary_details_bx tr:first-child th { */
.primaryDetailsBx tr:first-child td,
.primaryDetailsBx tr:first-child th {
  border-top: none;
}
/* .pro_details_model { */
.proDetailsModel {
  max-width: 700px;
}

/* .Primary_details_bx_ii td:last-child { */
.primaryDetailsBxIi td:last-child {
  max-width: 250px;
}
/* .modal-open div[tabindex] { */
.modalOpen div[tabindex] {
  z-index: 99999999 !important;
}
/* .Primary_details_bx_iii tr:first-child { */
.primaryDetailsBxIii tr:first-child {
  background: #3f51b5;
  color: #fff;
}

/* .Primary_details_bx_iii .Remark_box { */
.primaryDetailsBxIii .remarkBox {
  max-width: 250px;
}
/* .Primary_details_bx_iii { */
.primaryDetailsBxIii {
  margin-bottom: 20px;
  font-size: 14px;
}
/* .Primary_details_bx_iii a { */
.primaryDetailsBxIii a {
  font-size: 14px;
  color: #3f51b5;
}
/* .pb_ad_btn { */
.pbAdBtn {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
}

.providerNotice {
  width: 100%; /* to ensure the text can be in the center of the page */
  text-align: center;
  color: #666;
  font-size: 12px; /* choose the font size that suits you */
  margin-top: 20px; /* adjust this value as needed */
  padding: 10px; /* add padding to create space around the text inside the box */
  background-color: #f0f8ff; /* a light blue color, change to the color you want */
}

.providerNotice1 {
  width: 50%; /* to ensure the text can be in the center of the page */
  text-align: center;
  color: #666;
  font-size: 12px; /* choose the font size that suits you */
  margin-top: 20px; /* adjust this value as needed */
  padding: 1px; /* add padding to create space around the text inside the box */
  background-color: #f0f8ff; /* a light blue color, change to the color you want */
}

.fullWidthTextField {
  width: 100%;
  min-width: 100px; /* Or whatever minimum width makes sense for your design */
}

.buttonWithMargin {
  margin: 20px !important;
}

/* Improved spacing and distinct styling for 'Choose File' and 'Submit' buttons */
.fileAndSubmitContainer {
  display: flex;
  align-items: center; /* Align items vertically */
  width: 50%;
  justify-content: flex-start; /* Align items to the start of the flex container */
  gap: 10px; /* Manage spacing between the elements */
}

.chooseFileButton {
  background-color: #f0f0f0; /* Light gray background */
  color: #333; /* Dark text for contrast */
  border: 2px solid #ddd; /* Slightly darker border for distinction */
  padding: 5px 5px;
  border-radius: 4px;
  margin-bottom: 30px;
  flex-grow: 0; /* Prevent the file input from growing */
  flex-basis: auto; /* Adjust this to control the size of the file input */
  height: 36px;
}

.submitButton {
  flex-grow: 0 !important;
  padding: 6px 20px !important;
  margin-left: 10px !important;
  margin-bottom: 30px !important;
  background-color: #534caf !important; /* Just an example */
  color: white !important;
}

/* .modal-button-container { */
.modalButtonContainer {
  display: flex; /* Aligns items in a row */
  justify-content: center; /* Centers the buttons horizontally */
  align-items: center; /* Centers the buttons vertically */
  margin-top: 20px; /* Space above the button row */
  flex-shrink: 0; /* Prevents the button container from shrinking */
}

.modal2buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 10px 0;
}

/* .submit-2button { */
.submit2button {
  background-color: #0f912d; /* Green background for submit button */
  color: white; /* White text */
  min-width: 100px;
  height: 40px; /* Ensures all buttons have the same height */
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1; /* Ensures consistent line height */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

/* .submit-2button:disabled { */
.submit2button:disabled {
  background-color: #d7f0d7; /* Very light green for disabled state */
  cursor: not-allowed; /* Change cursor to not-allowed */
}

.cancel2button {
  background-color: #f44336; /* Red background for cancel button */
  color: white; /* White text */
  min-width: 100px;
  height: 40px; /* Ensures all buttons have the same height */
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1; /* Ensures consistent line height */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

/* Media Queries for Medium Screens (max-width: 960px) */

@media only screen and (max-width: 960px) {
  .mainContainer {
    padding: 15px;
  }

  .buttonWithMargin {
    margin: 15px !important;
  }

  .providerNotice {
    font-size: 11px;
    padding: 8px;
    width: 100%;
  }

  /* .Primary_details_bx h5 { */
  .primaryDetailsBx h5 {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Media Queries for Small Screens (max-width: 600px) */

@media only screen and (max-width: 600px) {
  .mainContainer {
    padding: 10px;
  }

  .buttonWithMargin {
    margin: 10px !important;
  }

  .providerNotice {
    font-size: 10px;
    padding: 6px;
  }

  /* .Primary_details_bx h5 { */
  .primaryDetailsBx h5 {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .mainContainer {
    padding: 100px; /* Reduced padding */
  }

  /* .Primary_details_bx tr th:first-child { */

  .primaryDetailsBx tr th:first-child {
    min-width: 100px; /* Reduced minimum width */
  }

  /* .Primary_details_bx h5 { */

  .primaryDetailsBx h5 {
    font-size: 14px; /* Smaller font size */
    padding: 8px 16px; /* Reduced padding */
  }

  /* .pro_details_model { */
  .proDetailsModel {
    max-width: 100%; /* Allow the modal to take up the full width */
  }

  /* .Primary_details_bx_ii td:last-child { */
  .primaryDetailsBxIi td:last-child {
    max-width: 100%; /* Allow the last cell to take up the full width */
  }

  providerNotice {
    font-size: 10px; /* Smaller font size */
    padding: 5px; /* Reduced padding */
  }

  .buttonWithMargin {
    margin: 10px !important; /* Reduced margin */
  }
}

/* Media Queries for Very Small Screens (max-width: 480px) */
@media only screen and (max-width: 480px) {
  .mainContainer {
    padding: 5px;
    overflow-x: hidden; /* hide horizontal overflow */
  }

  /* .Primary_details_bx tr th:first-child { */
  .primaryDetailsBx tr th:first-child {
    min-width: 50px; /* reduce minimum width */
  }

  /* Other adjustments here */

  .buttonWithMargin,
  .providerNotice,
  .primaryDetailsBx h5 {
    font-size: 9px; /* reduce font size */
    padding: 4px; /* reduce padding */
  }

  .buttonWithMargin {
    margin: 5px !important; /* reduce margin */
  }
}

/* Media Query for Small Screens */
@media only screen and (max-width: 600px) {
  /* .GridItemTextField { */
  .gridItemTextField {
    flex-direction: column !important;
  }

  /* Forcing each grid item to take full width */
  /* .GridItemTextField .MuiGrid-item { */
  .gridItemTextField .MuiGrid-item {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  /* Limit the maximum width of text fields and dropdowns */
  .fullWidthTextField {
    max-width: 100%; /* or whatever maximum width you want */
    margin: auto; /* center the field */
  }
  .buttonWithMargin {
    font-size: 20px; /* Increasing the font size */
    width: 25%; /* Make the button take full width */
    margin: 5px !important; /* Reduced margin */
  }

  /* .GridItemTextField { */
  .gridItemTextField {
    width: 100%; /* Make sure the grid item takes the full width */
  }

  .providerNotice {
    font-size: 10px; /* Reduce the font size */
    word-wrap: break-word; /* Break the words nicely */
    overflow-wrap: break-word; /* Break the words to next line */
    padding: 5px; /* Reduced padding */
    text-align: justify; /* Text alignment */
    margin: 5px 0; /* Reduced margin */
    width: 25%; /* Set the width to 25% of parent container */
  }
  .limitedWidth {
    max-width: 300px; /* or whatever max-width you find suitable */
  }
}
.providerNotice1 {
  font-size: 10px; /* Reduce the font size */
  word-wrap: break-word; /* Break the words nicely */
  overflow-wrap: break-word; /* Break the words to next line */
  padding: 5px; /* Reduced padding */
  text-align: justify; /* Text alignment */
  margin: 5px 0; /* Reduced margin */
  width: 75%; /* Set the width to 25% of parent container */
}

.borderedDropdown .MuiOutlinedInput-root {
  border: 1px solid #747272; /* Change #000 to any color you want */
  border-radius: 4px; /* Optional: For rounded corners */
}

.borderedDropdown
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #6f6a6a; /* Border color on hover */
}

.borderedDropdown .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #726f6f; /* Border color on focus */
}

/* .address-section { */
.addressSection {
  border: 1px solid #ccc; /* thin border */
  padding: 20px; /* space inside the border */
  margin-top: 30px; /* space above the section */
  margin-bottom: 30px; /* space below the section */
  background-color: #f9f9f9; /* light background color */
}

/* .address-section label { */
.addressSection label {
  display: block;
  margin-bottom: 10px; /* space below the label */
  font-weight: bold;
}

.priorityGreenRibbon {
  position: absolute;
  top: 5px; /* Adjust these values */
  right: 70px; /* Adjust these values */
  width: 190px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  background-color: #006400; /* Dark green color */
  color: #fff;
  padding: 20px 10px; /* Adjust these values */
  /* transform: rotate(-5deg) skew(-1deg); */
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3); /* Adds a nice shadow */
  /* border-radius: 50% 0 50% 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold; /* Makes text bold */
  z-index: 100;
}

.priorityOrangeRibbon {
  position: absolute;
  top: 5px; /* Adjust these values */
  right: 70px; /* Adjust these values */
  width: 190px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  background-color: #e4a506; /* Orange color */
  color: #fff;
  padding: 20px 10px; /* Adjust these values */
  /* transform: rotate(-5deg) skew(-1deg); */
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3); /* Adds a nice shadow */
  /* border-radius: 50% 0 50% 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold; /* Makes text bold */
  z-index: 100;
}

.highlightedText {
  font-weight: 900; /* Extra bold */
  color: #ffffff; /* Bright white */
  text-align: center; /* Center the text, though flex should already take care of this */
  display: block; /* Make it a block to fill the width */
  width: 100%; /* Ensure it fills the ribbon for proper centering */
}

.collapsedRibbon {
  top: 5px; /* Closer to the header when collapsed */
}

.expandedRibbon {
  top: 13px; /* Lower down when expanded */
}
