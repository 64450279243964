.providerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px; /* adjust width as necessary */
  height: 300px; /* adjust total height as necessary */
}

.providerImage {
  width: 100%; /* adjust width as necessary */
  height: 240px; /* adjust image height as necessary */
  object-fit: cover; /* ensures the image covers the area without distortion */
}

.providerName {
  height: 60px; /* adjust according to your needs */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; /* hides text that overflows */
}

.customTabSelected {
  background-color: #003366 !important; /* Darker blue */
  color: white !important;
}

.customTabRoot {
  text-transform: none;
  min-width: 172px;
  margin-top: -5px;
  padding: 0;
  justify-content: center;
  padding-left: 10px;
  background-color: grey;
}

.tabDescription {
  text-align: center;
  color: inherit;
  font-size: 0.75rem;
  padding-left: 10px;
  word-wrap: break-word; /* Legacy name for 'overflow-wrap' */
  overflow-wrap: break-word; /* Ensure words break to prevent overflow */
  white-space: normal;
}
