.filtersContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
}

.mainFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.mainFiltersLabel {
  margin-right: 0px !important;
}

.mainFiltersRadioButton {
  background-color: #28a745;
  color: #fff;
}

.subFilters {
  display: flex;
  flex-wrap: wrap;
  /* For desktop, display radio buttons horizontally */
  flex-direction: row;
  gap: 10px; /* Add some space between options */
}

.mainFiltersLabel,
.subFiltersLabel {
  margin-right: 0px;
}

.subFiltersRadioButton {
  background-color: #28a745 !important;
  color: #fff !important;
}

.radioGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radioOption {
  display: flex;
  align-items: center;
}

.radioButton {
  margin-right: 6px;
  height: 22px;
  width: 80px;
  border: 1px solid #ccc;
  margin-right: 2px;
  border-radius: 4px;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.radioButtonHover {
  background-color: #ccc;
  color: #fff;
}

.radioButtonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.searchField {
  display: flex;
  align-items: center;
  width: 200px;
  height: 60px;
  background-color: #fff;
  border-radius: 2px;
  font-size: 16px;
  margin-left: auto;
}

.searchFieldInput {
  display: flex;
  width: 190px;
  height: 17px;
  border: none;
  background-color: transparent;
  margin-left: auto;
  padding: 6px;
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
  margin-top: 20px;
}

.searchFieldInput::placeholder {
  color: #ccc;
  width: 170px;
}

.textFieldWrapper {
  border: 10px solid black;
  padding: 1px;
}

.searchFieldMuiFormLabelRoot {
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
}

/* Adjustments for mobile devices */
@media only screen and (max-width: 600px) {
  .subFilters {
    flex-direction: column; /* Stack radio buttons vertically */
    align-items: flex-start;
  }
  .searchField {
    display: flex;
    justify-content: center; /* Centers the child horizontally */
    margin-bottom: 20px; /* Adds spacing below the search box */
  }
  .searchFieldInput {
    margin-right: 0;
    align-content: center;
    right: 90px;
    /* Any other mobile-specific styles */
  }
  .filtersContainer {
    margin-top: 20px; /* Add space above the filters */
  }
  .sortContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 15px 0; /* Increase margin to move it further down */
    margin-bottom: -10px; /* Reduced from 20px to 10px */
  }
}

@media only screen and (min-width: 600px) {
  /* Hide the sort container on desktop if desired */
  .sortContainer {
    display: none;
  }
}
