hr {
  border: 0;
  height: 1px;
  background: #333;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Remove border from last row */
tbody tr:last-child td {
  border-bottom: none;
}

/* Add hover effect */
tbody tr:hover {
  background-color: #f5f5f5;
}

/* Field selection dropdown */
.fieldSelection {
  margin-bottom: 20px;
}

.fieldSelection select {
  width: 80%;
  height: 100px;
  display: block;
  /* margin: 0 auto; */
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping in case the width is too small */
}

.filter,
.fieldSelection {
  flex-basis: 30%; /* You can adjust this if needed */
  margin: 0 10px;
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Ensures children elements start at the beginning of the container */
  width: fit-content; /* Ensures container fits its content */
}

.checkboxContainer input[type='checkbox'] {
  margin: 0;
  padding: 0;
  margin-right: 5px; /* Adjust this to control the gap between the checkbox and the label */
}

.checkboxLabel {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.firstNameInput {
  margin-bottom: 10px; /* Adjust this value to control the space between First Name and Last Name inputs */
}

.emailInput {
  margin-bottom: 10px; /* Adjust this value to control the space between inputs */
  margin-top: -15px; /* Adjust this value to move Email up */
}

.phoneInput {
  margin-bottom: 10px; /* Adjust this value to control the space between inputs */
}

.dobPicker .MuiFormControl-root {
  margin-bottom: 115px !important;
  margin-top: -110px !important;
}

/* Add a bottom border to the label within the sidebar-input div */
.sidebarInput label {
  border-bottom: 1px solid #333;
  padding-bottom: 5px;
}

.sidebarInput.therapists {
  margin-top: 15px;
}

.sidebarInput.status {
  margin-top: -25px;
}

.sidebarInput.primaryInsurance {
  margin-top: -20px;
}
/* Adjust spacing for Primary Insurance and Account Number */
.sidebarInput.secondaryInsurance {
  margin-top: -20px;
}

.sidebarInput.accountNumber {
  margin-top: -30px; /* adjust this value to reduce/increase space */
}

.therapistsPlaceholder {
  height: 10px; /* adjust this value based on your needs */
}

/* Highlight the selected row in light yellow */
tbody tr.selected-row > td {
  background-color: lightyellow;
}
/* CSS for the bottom menu bar */
.bottomMenuBar {
  position: fixed;
  bottom: 0;
  left: 20%; /* Set left to 20% to center the menu bar */
  width: 50%; /* Set width to 60% as desired */
  background-color: #fff; /* Set your desired background color */
  border-top: 1px solid #001e60; /* Add a top border for separation */
  border-right: 1px solid #001e60; /* Top border */
  border-left: 1px solid #001e60; /* Left border */
  padding: 20px; /* Add padding as needed */
  display: flex;
  justify-content: space-between; /* Align buttons to the ends */
}
.statusButton {
  cursor: pointer;
  text-align: center;
}

.dots {
  font-size: 24px; /* Large dots */
  line-height: 1; /* Adjust line height to tighten the spacing */
}

.statusText {
  font-size: 16px;
}

.listItemButton {
  cursor: pointer; /* Makes the mouse turn into a click hand */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.listItemButton:hover {
  background-color: #e0e0e0; /* Color when the item is hovered */
}

.listItemButton.selected {
  background-color: blue; /* Color when the item is selected */
  color: white;
}

.confirmationModalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationModal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Make sure it's above other items */
}

.highlightedRow {
  background-color: lightgreen;
}
.centeredHeader {
  text-align: center;
}

/* Updated to use flex for centering the radio inputs */
.centeredRadio {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for overlay */
  z-index: 1001; /* Make sure it's above other elements */
}

.modalContent {
  width: 60%;
  max-width: 300px;
  padding: 20px; /* Add padding inside the modal */
  /* Your other styles */
  background-color: white; /* Optional: Set background color */
  border-radius: 5px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for depth */
  /* Remove margin: 0 auto; as it's no longer needed */
}

.confirmationModal {
  width: 400px; /* or any other fixed width */
  /* other styles */
}

.stickyHeader {
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 20;
}

.headerTitle {
  font-size: larger; /* Or any specific size */
  margin-bottom: 4px; /* Space between the text and the icon */
}

.headerWithTooltip {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically, adjust as needed */
  text-align: center; /* Center the text */
  height: 100%; /* Ensure the div fills the cell */
  padding: 8px; /* Add padding as necessary */
}

.hidden {
  display: none;
}

.topRightIcon {
  margin-left: auto; /* Pushes the icon to the right */
}

.grayedOut {
  opacity: 0.5;
  pointer-events: none; /* Disables interactions */
}

.tableContainer {
  position: relative; /* This makes it a positioning context for the overlay */
}

.submissionOverlay {
  position: absolute;
  z-index: 10; /* Higher than the table but not higher than the tabs or select dropdown */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all; /* This will be overridden by the next class when needed */
  flex-direction: column; /* Stack children elements vertically */
}
/* Add this new class */
.overlayInactive {
  pointer-events: none;
}

.submissionLockedText {
  font-size: 24px; /* Adjust font size as needed */
  font-weight: bold; /* Make the font bold */
  margin-bottom: 5px; /* Add some space between the two lines */
}

.countdownTimer {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left; /* Default for desktop view */
}
.greyedOutRow {
  background-color: #e0e0e0; /* Grey color */
}
.explanationText {
  font-size: 16px; /* Adjust font size as needed */
  color: #ccc; /* Lighter text color for the explanation */
  font-weight: bold;
  max-width: 600px; /* Set a max-width for better readability */
  text-align: center; /* Ensure the text is centered */
}
.clientName {
  cursor: default; /* Default non-clickable cursor on larger screens */
}

@media (max-width: 768px) {
  .rostersTableContainer table {
    display: none;
  }

  .container {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: -40px;
    margin-left: -20px;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    min-width: 300px;
  }

  .countdownTimer {
    text-align: center; /* Center for mobile view */
  }

  /* Show mobile view for Rosters on smaller screens */
  .mobileRosterList {
    display: block;
    width: 100%;
    margin-left: 40px;
    margin-top: 20px;
  }

  /* Additional styling for the mobile view of Rosters */
  .mobileRosterCard {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
  }

  .rosterClientName {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0px;
    margin: 0 auto;
  }

  .clientName {
    cursor: pointer; /* Change to a clickable cursor on smaller screens */
  }

  .centeredRadio label {
    display: flex; /* Ensures label text aligns properly with the radio button */
    align-items: center; /* Centers the label with the radio button vertically */
    margin-left: 5px; /* Space between the radio button and label text */
    cursor: pointer; /* Indicates the label is clickable */
    text-align: left; /* Ensures text is aligned to the left */
    width: 100%; /* Ensures label takes the full width available */
  }

  .centeredRadio input[type='radio'] {
    transform: scale(1.5); /* Adjust the scaling factor as needed */
    margin-right: 10px; /* Space between the radio button and label */
    cursor: pointer; /* Indicates the radio button is clickable */
  }

  .rosterClientDetails {
    font-size: smaller;
    color: #000;
    text-align: left; /* Ensures all details in the card are aligned to the left */
  }
  .selectedStatus {
    margin-left: 10px;
    font-weight: bold;
    color: #555; /* Or any color that fits your design */
  }
  .mobileRosterCard.selected {
    background-color: #e6ffe6; /* Light green background to indicate selection */
  }
  .submissionOverlay {
    position: absolute;
    z-index: 10; /* Higher than the table but not higher than the tabs or select dropdown */
    top: 0;
    left: 40px;
    right: -40px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all; /* This will be overridden by the next class when needed */
    flex-direction: column; /* Stack children elements vertically */
  }
}

@media (min-width: 769px) {
  .mobileRosterList {
    display: none;
  }
}
