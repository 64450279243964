.centeredContent {
  text-align: center;
  /* Add any additional styling here that may be required for alignment */
}

.centeredRadio {
  text-align: center;
  transform: scale(1.5); /* Adjust the scaling factor as needed */
  /* Add any additional styling here that may be required for alignment */
}

.centeredRadio input[type='radio'] {
  cursor: pointer; /* Indicates the radio button is clickable */
  margin-right: 10px; /* Space between the radio button and label */
  transform: scale(1.1); /* Adjust the scaling factor as needed */
}

.confirmationModal {
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1001; /* Make sure it's above other items */
}

.confirmationModalBackdrop {
  align-items: center; /* Center children horizontally */
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed backdrop */
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.copyButton {
  display: block;
  margin: 16px auto;
  padding: 10px 20px;
}

.headerWithTooltip {
  align-items: center; /* Center children horizontally */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: 100%; /* Ensure the div fills the cell */
  justify-content: center; /* Center children vertically, adjust as needed */
  padding: 8px; /* Add padding as necessary */
  text-align: center; /* Center the text */
}

.highlightedRow {
  background-color: lightgreen;
}

.hoverText {
  cursor: pointer;
  margin-right: 5px;
  text-decoration: underline;
}

.modalContent {
  background-color: white; /* Optional: Set background color */
  border-radius: 5px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for depth */
  display: flex; /* New: Organizes children in a flex layout */
  flex-direction: column; /* New: Stacks children vertically */
  justify-content: space-between; /* New: Aligns children with space between them */
  max-height: 80vh; /* New: Sets a max height for the modal */
  max-width: 500px !important;
  overflow: hidden; /* New: Prevents modal from overflowing */
  padding: 20px; /* Add padding inside the modal */
  width: 70%;
}

.mobileDipList {
  display: none; /* Hide mobile menu in regular view */
}

.dipClientName {
  cursor: pointer;
  font-weight: bold;
}

@media (max-width: 768px) {
  .dipClientDetails,
  .centeredRadio {
    text-align: left;
    margin-top: 10px;
  }

  .dipClientDetails label,
  .dipClientDetails span {
    text-align: left;
    display: block; /* This ensures they take the full width and respect text alignment */
  }
  .mobileDipList {
    display: block;
  }

  .mobileDipCard {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    margin-left: 32px;
    padding: 10px;
  }
}
